import React, { useState, useEffect, useMemo, useContext } from "react";
import { connect } from "react-redux";
import { Container, Card, Row, Col, Form, Table, Button, ListGroup } from "react-bootstrap";
import Icon from "react-icons-kit";
import {ic_delete_outline} from "react-icons-kit/md";
import { useParams, Navigate } from "react-router-dom";
import MainWrapper from "../../MainWrapper";
import PricingContract from "../customer/models/PricingContract";
import { 
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_UNIT_DETAIL_REQUEST,
  CUSTOMER_ORDER_DETAIL_REQUEST,
  CUSTOMER_STATE_CHANGE,
  SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST,
  CUSTOMER_ORDER_UNIT_CHANGE_REQUEST,
  CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST,
  CUSTOMER_ORDER_RESCHEDULE_REQUEST,
  CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST,
  CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST,
  CUSTOMER_ORDER_ADDON_SAVE_REQUEST,
  GET_SHORTAGE_REASON_REQUEST,
  SAVE_ORDER_SHORTAGE_DETAIL_REQUEST,
  UPDATE_SHORTAGE_REASON_REQUEST,
  GET_QUALITY_REASON_REQUEST,
  SAVE_ORDER_QUALITY_DETAIL_REQUEST,
 } from "../../../_utils/constants/Customer";
 import {
  CUSTOMER_ORDER_MATERIAL_REQUEST,
  CUSTOMER_ORDER_INSTALLATION_REQUEST,
  CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST,
  CUSTOMER_ORDER_LABOR_DELETE_REQUEST
 } from "../../../_utils/constants/PricingContract";
 import { useNavigate } from "react-router-dom";
 import ConfirmDelete from "../../elements/ConfirmDelete";
 import Input from "../../elements/CustomInput";
 import { toast } from 'react-toastify';
import ChangeJob from "./models/ChangeJob";
import {Context} from "../../../App";

const OrderConfirmation = ({
  auth: { auth, isAuthenticated },
	customerInfo: { customerList, customerOrderDetails, customerSaveOrderDetails, customerSaveContractDetails, isLoading, errors, isDateRescheduled, orderRescheduledDetails, orderAddOnDetails, saveOrderAddOnDetails, shortageReason, saveOrderShortage, updateShortageReason, qualityReasons, saveOrderQualityDetail },
  pricingContract: { orderMaterialData, orderInstallationData, deletedOrderMaterialData, deletedOrderLaborData, isProcessing },
  fetchOrderInstallationDetail,
  fetchCustomerList,
	saveCustomerOrderDetails,
  changeReducerState,
  fetchOrderInstallationMaterial,
  fetchOrderInstallationOrder,
  saveCustomerOrderDetailsForPricingContract,
  deleteOrderMaterialData,
  deleteOrderLaborData,
  saveCustomerOrderUnitId,
  saveCustomerOrderUnitTypeId,
  saveCustomerOrderRescheduleData,
  fetchCustomerOrderRescheduledData,
  fetchCustomerOrderAddOnDetails,
  saveCustomerOrderAddOnDetails,
  getShortageReasonRequest,
  saveOrderShortageRequest,
  updateShortageReasonRequest,
  getQualityReasonRequest,
  saveOrderQualityDetailRequest,
}) => {

  const navigate = useNavigate();
  const {pushervalue} = useContext(Context);
  // states declaration
  const { contactInfoId } = useParams()
  const { unitId } = useParams()
  const { jobNum } = useParams()
  const [pricingContractModalOpen, setPricingContractModalOpen] = useState(false)
  const [laborContractModalOpen, setLaborContractModalOpen] = useState(false)
  const [materialContractModalOpen, setMaterialContractModalOpen] = useState(false)
  const [customerData, setCustomerData] = useState()
  const [customerOrderData, setCustomerOrderData] = useState()
  const [materialListData, setMaterialListData] = useState()
  const [orderInstallationListData, setOrderInstallationListData] = useState()
  const [state, setState] = useState({
    "newUnitId": "",
    "newUnitTypeId": "",
    "description": "",
    "internalNotes": "",
    "installerInstructions": "",
    "tax": "",
  })
  const [stateLaborFilter, setStateLaborFilter] = useState({
		"laborName": "",
		"storeId": "",
		"ContactInfoId": "",
		"grpPricingContractId": "",
		"productTypeId": 0,
		"productStructureId": 0,
		"laborCategoryId": 0,
		"unitId": 0,
		"currentCost": 0,
		"futureCost1": 0,
		"increaseDate": "",
		"sortOn": "",
	});
  const [lsUpdate, setLsUpdate] = useState(false)
  const [lsLaborUpdate, setLsLaborUpdate] = useState(false)
  const [lsLaborMaterialUpdate, setLsLaborMaterialUpdate] = useState(false)

  const [orderInstallationCarpetData, setOrderInstallationCarpetData] = useState()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteOrderData, setDeleteOrderData] = useState()
  const [deleteOrder, setDeleteOrder] = useState()
  const [totalMaterialUnitPrice, setTotalMaterialUnitPrice] = useState()
  const [totalMaterialLinePrice, setTotalMaterialLinePrice] = useState()
  const [totalLaborUnitPrice, setTotalLaborUnitPrice] = useState()
  const [totalLaborLinePrice, setTotalLaborLinePrice] = useState()
  const [orderTotal, setOrderTotal] = useState()
  const [checkOrder, setCheckOrder] = useState(false)
  const [isChangeJob, setIsChangeJob] = useState(false)
  const [startDate, setStartDate] = useState()
  const [rescheduleData, setRescheduleData] = useState({
    'RescheduleChoicesId': '',
    'changeJobNotes': ''
  })
  const [ selectedCheckbox, setSelectedCheckbox] = useState([])
  const [ orderShortageList, setOrderShortageList ] = useState([])
  const [ changeJobHeader, setChangeJobHeader ] = useState('Change Job')
  const [ addOnData, setAddOnData ] = useState({
    'approvedBy': '',
    'description': ''
  })
  const [ isStateChanged, setIsStateChanged ] = useState(false)
  const [orderingUrl, setIsOrderingUrl] = useState();
  const [processStart, setProcessStart] = useState(false);

  useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

  // function to check if order generated or is selected
  const orderedPlaced = () => {
    let fromExistingOrder = localStorage.getItem('Existing_Orders');
    if (fromExistingOrder === 'true') {
      return true
    } else {
      return false
    }
  }

  // function to call customer detail api
	useEffect(() => {
		if (isAuthenticated) {
			fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': contactInfoId }, auth[0].userId, false, false, false)
      getShortageReasonRequest(auth[0].token, '')
      fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())
      fetchOrderInstallationMaterial(auth[0].token, jobNum)
      fetchOrderInstallationOrder(auth[0].token, jobNum)
		}
		if (!isAuthenticated) return <Navigate to="/login" />
	}, [auth, isAuthenticated]);

  // function called to modify order listing
	useEffect(() => {
		localStorage.setItem('Order-Listing', 'on');
	}, [])

  // for update the shortage reason every time
  useEffect(()=>{fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())},[updateShortageReason])

  // function to handle view from existing order
  const handleExistingOrder = () => {
    let fromExistingOrder = localStorage.getItem('Existing_Orders');
    if (fromExistingOrder === 'true') {
      setCheckOrder(true)
    } else {
      setCheckOrder(false)
    }
  }

  useEffect(() => {
    handleExistingOrder()
  }, [checkOrder, localStorage.getItem('Existing_Orders')])

  // function to manage customer list data
  useMemo(() => {
    if(customerList.length > 0) {
      console.log('---customerList---', customerList)
      setCustomerData(customerList)
      setStateLaborFilter({
        ...stateLaborFilter,
        "storeId": customerList[0].storeId,
        "ContactInfoId": contactInfoId
      })
    }
  }, [customerList])

  // function to manage customer order details
  useMemo(() => {
    if(customerOrderDetails) {
      setOrderShortageList(customerOrderDetails ? customerOrderDetails[0]?.orderShortage : [])
      setCustomerOrderData(customerOrderDetails)
      customerOrderDetails.map(data => {
        setState({
          "newUnitId": data?.orderInstallationDetails.UnitName ? data?.orderInstallationDetails.UnitName : "",
          "newUnitTypeId": data?.orderInstallationDetails.UnitTypeName ? data?.orderInstallationDetails.UnitTypeName : "",
          "description": data?.orderInstallationDetails.Description ? data?.orderInstallationDetails.Description : "",
          "internalNotes": data?.orderInstallationDetails.InternalNotes ? data?.orderInstallationDetails.InternalNotes : "",
          "installerInstructions": data?.orderInstallationDetails.Instruction ? data?.orderInstallationDetails.Instruction : "",
          "tax": ""
        })
      })
    }
  }, [customerOrderDetails])
 
  // handle click make change
  const handleMakeChangeClose = () => {
    setIsChangeJob(false)
    setStartDate()
    setChangeJobHeader('Change Job')
    setRescheduleData({
      'RescheduleChoicesId': '',
      'changeJobNotes': ''
    })
    setAddOnData({
      'approvedBy': '',
      'description': ''
    })
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
  }

  // handle click make change
  const handleMakeChangeModalOpen = () => {
    setIsChangeJob(true)
  }

  // handle click make change
  const handleMakeChangeClick = () => {
    handleMakeChangeModalOpen()
  }

  useEffect(() => {
    if (changeJobHeader === 'Reschedule' && isDateRescheduled[0] === 'saved') {
      fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())
      toast.success(isDateRescheduled[0])
      handleMakeChangeClose()
      setIsChangeJob(false)
    } else if (changeJobHeader === 'Add On' && isDateRescheduled[0] === 'saved') {
      fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())
      toast.success(isDateRescheduled[0])
      handleMakeChangeClose()
      setIsChangeJob(false)
    } else if (changeJobHeader === 'Reschedule' && isDateRescheduled[0]) {
      toast.error(isDateRescheduled[0])
    }
  }, [isDateRescheduled])

  // handle reschedule date edit case
  // useEffect(() => {
  //   if (orderRescheduledDetails && orderRescheduledDetails[0] && orderRescheduledDetails.length > 0) {
      // setRescheduleData({
      //   'RescheduleChoicesId': orderRescheduledDetails[0].reason,
      //   'changeJobNotes': orderRescheduledDetails[0].note
      // })
      // setStartDate(orderRescheduledDetails[0].orderInstallationDate)
  //   }
  // }, [orderRescheduledDetails])

  //reset all state
  const resetState = () => {
    setMaterialContractModalOpen()
    setCustomerData()
    setCustomerOrderData()
    setMaterialListData()
    setOrderInstallationListData()
    setState({
      "newUnitId": "",
      "newUnitTypeId": "",
      "description": "",
      "internalNotes": "",
      "installerInstructions": "",
      "tax": "",
    })
    setStateLaborFilter({
      "laborName": "",
      "storeId": "",
      "ContactInfoId": "",
      "grpPricingContractId": "",
      "productTypeId": 0,
      "productStructureId": 0,
      "laborCategoryId": 0,
      "unitId": 0,
      "currentCost": 0,
      "futureCost1": 0,
      "increaseDate": "",
      "sortOn": "",
    })
    setOrderInstallationCarpetData()
    setConfirmDeleteModal()
    setDeleteOrderData()
    setDeleteOrder()
    setTotalMaterialUnitPrice()
    setTotalMaterialLinePrice()
    setTotalLaborUnitPrice()
    setTotalLaborLinePrice()
    setOrderTotal()
  }

  // handle save order details api response
  useMemo(() => {
    if(customerSaveOrderDetails.length > 0 && customerSaveOrderDetails[0] === "save") {
      resetState()
      navigate(`/place-order`)
    }
    if(customerSaveOrderDetails.length > 0 && customerSaveOrderDetails[0] !== "save") {
      toast.error(customerSaveOrderDetails[0])
    }
  }, [customerSaveOrderDetails])

  // function called to modify customer listing
	useEffect(() => {
		localStorage.setItem('Customer-Listing', 'on')
		handleExistingOrder()
	}, [])

  //handle api response errors
  useEffect(() => {
    if(errors && errors?.data && (errors?.data?.title||errors?.data?.result)){
      toast.error(errors?.data?.title)
    if(errors?.data?.result.length > 0 ){
        const msg = errors?.data?.result
        msg.map((m)=>{
          toast.error(m)
        })
      }}
  }, [errors])

  // handle labor and material listing
  const materialAndLaborListing = () => {
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
  }

  // function to handle digits after decimal
  const formatNumberWithTwoDigitsAfterDecimal = (number) => {
    const numberString = parseFloat(number).toFixed(2); // Convert the number to a string with two decimal places
    const decimalIndex = numberString.indexOf('.');
    
    if (decimalIndex === -1) {
      // No decimal point found, so add two zeros after the number
      return numberString + '.00';
    }
  
    const digitsAfterDecimal = numberString.length - decimalIndex - 1;

    if (digitsAfterDecimal < 2) {
      // Add a zero after the number to make it two digits after the decimal point
      return numberString + '0';
    }
  
    // Return the number as is, already with two digits after the decimal point
    return numberString;
  }

  
  const [localStorageData, setLocalStorageData] = useState({})

  const loadLocalStorageDataSetup = () => {
    let data = JSON.parse(localStorage.getItem('selectedAllCarpetData') || '{}');
    if(data){
      setLocalStorageData(data)
      console.log("set data",data,localStorageData)
    }
  }
  
  const [ localStorageLaborData, setLocalStorageLaborData ] = useState([])

  const loadLocalStorageLaborDataSetup = () => {
    let data = JSON.parse(localStorage.getItem('copySelectedLaborList') || '{}');
    if(data){
      setLocalStorageLaborData(data)
      console.log("set data localStorageLaborData", data, localStorageLaborData)
    }
  }

  const [ lsAllLaborMaterialCarpetData, setLsAllLaborMaterialCarpetData ] = useState({})
  
  const loadLocalStorageLaborMaterialCarpetData = () => {
    let data = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData') || '{"materials":[],"labors":[]}');
    if(data){
      setLsAllLaborMaterialCarpetData(data)
      console.log("set data loadLocalStorageLaborMaterialCarpetData", data, lsAllLaborMaterialCarpetData)
    }
  }

  // handle material listing api response
  useMemo(() => {
    if(orderMaterialData) {
      let tempOrderMaterial = orderMaterialData
      if (tempOrderMaterial.length  > 0 ) {
        tempOrderMaterial.map(data => {
          data.quantity = formatNumberWithTwoDigitsAfterDecimal(data.quantity)
          data.unitPrice = formatNumberWithTwoDigitsAfterDecimal(data.unitPrice)
          data.linePrice = formatNumberWithTwoDigitsAfterDecimal(data.linePrice)
        })
        tempOrderMaterial = Object.values(tempOrderMaterial)
      }
      setMaterialListData(tempOrderMaterial)
    }
  }, [orderMaterialData])

  // handle order installation listing api response
  useMemo(() => {
    if(orderInstallationData) {
      let tempOrderInstallation = orderInstallationData
      if (tempOrderInstallation.length  > 0 ) {
        tempOrderInstallation.map(data => {
          data.quantity = formatNumberWithTwoDigitsAfterDecimal(data.quantity)
          data.unitPrice = formatNumberWithTwoDigitsAfterDecimal(data.unitPrice)
          data.linePrice = formatNumberWithTwoDigitsAfterDecimal(data.linePrice)
        })
        tempOrderInstallation = Object.values(tempOrderInstallation)
      }
      setOrderInstallationListData(orderInstallationData)
    }
  }, [orderInstallationData])

  // clear storage data
  const clearStorageState = () => {
    localStorage.removeItem("selectedProductList")
    localStorage.removeItem("selectedLaborList")
    localStorage.removeItem("selectedMaterialList")
    localStorage.removeItem("selectedOrderLaborList")
  }

  //handle order save contract details data
  useEffect(() => {
    if(customerSaveContractDetails[0] === 'saved' && (materialContractModalOpen || laborContractModalOpen) && orderInstallationCarpetData) {
      setMaterialContractModalOpen(false)
      setLaborContractModalOpen(false)
      fetchOrderInstallationMaterial(auth[0].token, jobNum)
      fetchOrderInstallationOrder(auth[0].token, jobNum)
      setOrderInstallationCarpetData(false)
      clearStorageState()
    }
    if(customerSaveContractDetails[0] === 'not saved' && (materialContractModalOpen || laborContractModalOpen)) {
      toast.error(customerSaveContractDetails[0])
    }
  }, [customerSaveContractDetails])

  // handle delete material api response
  useEffect(() => {
    if(deletedOrderMaterialData[0] === 'deleted') {
      materialAndLaborListing()
      setConfirmDeleteModal(false)
    }
  }, [deletedOrderMaterialData])

  // handle delete labor api response
  useEffect(() => {
    if(deletedOrderLaborData[0] === 'deleted') {
      materialAndLaborListing()
      setConfirmDeleteModal(false)
    }
  }, [deletedOrderLaborData])

  // handle materialListData for listing
  useEffect(() => {
    if(materialListData && materialListData.length>0) {
      let totalUnitPrice = 0.00
      let totalLinePrice = 0.00
      materialListData.map(data => {
        totalUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : 0.00
        totalLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00
      })
      setTotalMaterialUnitPrice(totalUnitPrice)
      setTotalMaterialLinePrice(totalLinePrice)
    }
  }, [materialListData])

  // handle laborListData for listing
  useEffect(() => {
    if(orderInstallationListData && orderInstallationListData.length>0) {
      let totalUnitPrice = 0.00
      let totalLinePrice = 0.00
      orderInstallationListData.map(data => {
        totalUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : 0.00
        totalLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00
      })
      setTotalLaborUnitPrice(totalUnitPrice)
      setTotalLaborLinePrice(totalLinePrice)
    }
  }, [orderInstallationListData])

  // function to handle order total
  useMemo(() => {
    if(totalLaborUnitPrice, totalLaborLinePrice, totalMaterialUnitPrice, totalMaterialLinePrice) {
      let orderTotal = parseFloat(totalLaborLinePrice ? totalLaborLinePrice : 0.00) + parseFloat(totalMaterialLinePrice ? totalMaterialLinePrice : 0)
      setOrderTotal(orderTotal)
    }
  }, [totalLaborLinePrice, totalMaterialLinePrice])

  // function to handle saved add on data
  useEffect(() => {
    if (saveOrderAddOnDetails && saveOrderAddOnDetails.length > 0) {
      if (changeJobHeader === 'Add On' && saveOrderAddOnDetails[0] === 'AddOn Details Saved Successfully') {
        toast.success(saveOrderAddOnDetails[0])
        fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())
        fetchOrderInstallationMaterial(auth[0].token, jobNum)
        fetchOrderInstallationOrder(auth[0].token, jobNum)
        handleMakeChangeClose()
      } else if (changeJobHeader === 'Add On' && saveOrderAddOnDetails[0]) {
        toast.error(saveOrderAddOnDetails[0])
      }
    }
  }, [saveOrderAddOnDetails])

  const handleUpdateCustomerDetail = () => {
		fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': id }, auth[0].userId, false, false, false);
	}

  // function called on input change event
	const handleChange = (e) => {
		const { id, value } = e.target
		setState({
      ...state,
      [id]: value
    })
    // Commented on 03-07-2024 for add on implementation
    // if (id === "newUnitId") {
    //   let payload = {
    //     UnitName: e.target.value,
    //     ContactInfoId: parseInt(contactInfoId),
    //     UnitId: parseInt(unitId)
    //   }
    //   saveCustomerOrderUnitId(auth[0].token, payload)
    // }
    // if (id === "newUnitTypeId") {
    //   let payload = {
    //     UnitTypeName: e.target.value,
    //     ContactInfoId: parseInt(contactInfoId),
    //     UnitTypeId: parseInt(customerOrderData[0].UnitTypeId) ? parseInt(customerOrderData[0].UnitTypeId) : 0
    //   }
    //   saveCustomerOrderUnitTypeId(auth[0].token, payload)
    // }
	}

  // function to handle reschedule job change
  const handleRescheduleChange = (e) => {
    const { id, value } = e.target
    setRescheduleData(
			{
				...rescheduleData,
				[id]: value
			}
		)
  }

  // function to open pricing contract modal
	const showPricingContractModal = () => {
    clearStorageState()
		setPricingContractModalOpen(true)
    setMaterialContractModalOpen(true)
	}

	// function to close pricing contract modal
	const hidePricingContractModal = () => {
    clearStorageState()
		setPricingContractModalOpen(false)
    setMaterialContractModalOpen(false)
    setLaborContractModalOpen(false)
    if(localStorage.getItem("selectedAllCarpetData")){
      setLsUpdate(true)
      loadLocalStorageDataSetup()
    }
    if(localStorage.getItem("copySelectedLaborList")){
      setLsLaborUpdate(true)
      loadLocalStorageLaborDataSetup()
    }
    if(localStorage.getItem("selectedAllLaborMaterialCarpetData")){
      setLsLaborMaterialUpdate(true)
      loadLocalStorageLaborMaterialCarpetData()
    }
	}

  // function to add new material
  const handleClickNewMaterialItem = () => {
    clearStorageState()
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":true,"addNewLaborItem":false}))
    setPricingContractModalOpen(true)
    setMaterialContractModalOpen(true)
  }

  // function to add new labor
  const handleClickNewLabourItem = () => {
    changeReducerState()
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":false,"addNewLaborItem":true}))
    clearStorageState()
    setLaborContractModalOpen(true)
    setPricingContractModalOpen(true)
  }

  // handle edit change on material listing
  const handleMaterialListingEditChange = (value, data, filterValue) => {
    if(materialListData && materialListData.length > 0) {
      let materialData = materialListData
      materialData.map(editedData => {
        if((data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        }
        if((data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) && filterValue === 'unitPrice') {
          editedData.unitPrice = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.quantity)).toFixed(2)
        }
        if((data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) && filterValue === 'linePrice') {
          editedData.linePrice = value
          editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice)).toFixed(2)
        }
      })
      materialData = Object.values(materialData)
			setMaterialListData(materialData)
    }
  }
  
  // function call on modifying material kit listing
	const handleMaterialItemChange = (e, data, filterValue) => {
		handleMaterialListingEditChange(e.target.value, data, filterValue)
	}

  // handle edit change on labor listing
  const handleLaborListingEditChange = (value, data, filterValue) => {
    if(orderInstallationListData && orderInstallationListData.length > 0) {
      let laborData = orderInstallationListData
      laborData.map(editedData => {
        if(data.orderLaborId === editedData.orderLaborId && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        }
        if(data.orderLaborId === editedData.orderLaborId && filterValue === 'unitPrice') {
          editedData.unitPrice = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.quantity)).toFixed(2)
        }
        if(data.orderLaborId === editedData.orderLaborId && filterValue === 'linePrice') {
          editedData.linePrice = value
          editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice)).toFixed(2)
        }
      })
      laborData = Object.values(laborData)
			setOrderInstallationListData(laborData)
    }
  }

  // function call on modifying labor listing
	const handleLaborItemChange = (e, data, filterValue) => {
		handleLaborListingEditChange(e.target.value, data, filterValue)
	}

  // function called to save updated listing for material and labor
  const handleMaterialLaborListingUpdate = () => {
    let productList = []
    let materialList = []
    let laborList = []
    materialListData.map(data => {
      if(data.orderProductId) {
        productList.push({
          ProductId: data.productId,
          Quantity: parseFloat(data.quantity),
          GPM: parseFloat(data.gpm),
          LinePrice: parseFloat(data.linePrice),
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: parseFloat(data.unitPrice),
        })
      }
      if(data.orderMaterialId) {
        materialList.push({
          MaterialId: parseFloat(data.materialId),
          Product: parseFloat(data.productId),
          Quantity: parseFloat(data.quantity),
          GPM: parseFloat(data.gpm),
          LinePrice: parseFloat(data.linePrice),
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: parseFloat(data.unitPrice),
        })
      }
    })
    if(orderInstallationListData && orderInstallationListData.length>0) {
      orderInstallationListData.map(data => {
        laborList.push({
          LaborItemId: data.laborItemId,
          LaborId: data.laborId,
          Quantity: data.quantity,
          GPM: data.gpm,
          LinePrice: data.linePrice,
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: data.unitPrice,
        })
      })
    }
    let payload = {
      OrderMaterial: materialList,
      ProductOrder: productList,
      LaborOrder: laborList
    }
    saveCustomerOrderDetailsForPricingContract(auth[0].token, payload)
  }

  //function to save order details
  const handleSaveClick = () => {
    handleMaterialLaborListingUpdate()
    let payload = {
      "UnitName": state.newUnitId,
      "UnitType": state.newUnitTypeId,
      "Notes": state.internalNotes ? state.internalNotes : "",
      "InstallationInstracution": state.installerInstructions ? state.installerInstructions : "",
      "ContactInfoId": parseInt(contactInfoId),
      "OrderInstallationDetailId": customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
      "Description": state.description ? state.description : "",
      "OldUnitId": parseInt(unitId),
      "OldUnitType": parseInt(customerOrderData[0].UnitTypeId) ? parseInt(customerOrderData[0].UnitTypeId) : 0
    }
    saveCustomerOrderDetails(auth[0].token, payload)
  }

  //function to save contract for order details
  const saveContractForOrderDetails = (data, bool) => {
    setOrderInstallationCarpetData(bool)
    saveCustomerOrderDetailsForPricingContract(auth[0].token, data)
  }

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteOrderData('')
    setConfirmDeleteModal(false)
  }

  // function called for delete api
  const handleConfirmDeleteSubmit = () => {
    let checkFlag = deleteOrderData.orderMaterialId ? true : false
    if(deleteOrderData.orderMaterialId) {
      deleteOrderMaterialData(auth[0].token, deleteOrderData.orderMaterialId, checkFlag)
    } else if(deleteOrderData.orderProductId) {
      deleteOrderMaterialData(auth[0].token, deleteOrderData.orderProductId, checkFlag)
    } else {
      deleteOrderLaborData(auth[0].token, deleteOrderData.orderLaborId)
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (data) => {
    if(data.orderLaborId) {
      setDeleteOrder('labor')      
    } else {
      setDeleteOrder('material')
    }
    setDeleteOrderData(data)
    setConfirmDeleteModal(true)
  }

  // handle remove material data
  const handleMaterialDeleteClick = (data) => {
    showConfirmDeleteModal(data)
  }

  // handle remove labor data
  const handleLaborDeleteClick = (data) => {
    showConfirmDeleteModal(data)
  }

  // handle cancel click
  const handleCancelClick = () => {
    navigate(`/place-order`)
  }

  // function to format a number with commas every 3 digits
  const handleFormat = (number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  // handle formatting of date
  const handleFormatDate = (installationDate) => {
    const dateObj = new Date(installationDate);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
    return formattedDate;
  }

  // parse the date | database to frontend
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    
    return `${month}.${day}.${year}`;
  };

  const handleChangeUpdateShortageReasons = (e, item) =>{
    
    const { checked } = e.target;
    if(checked){
      let payload = {
        "orderShortageReasonId": item.orderShortageReasonId,
        "shortageReasonId": item.shortageReasonId,
        "orderShortageDetailId": item.orderShortageDetailId,
        "isSelected": true,
      }
      updateShortageReasonRequest(auth[0].token, payload)
      setSelectedCheckbox(selectedCheckbox => [...selectedCheckbox,item.shortageReasonId,]);
    } else {
      let payload = {
        "orderShortageReasonId": item.orderShortageReasonId,
        "shortageReasonId": item.shortageReasonId,
        "orderShortageDetailId": item.orderShortageDetailId,
        "isSelected": false,
      }
      updateShortageReasonRequest(auth[0].token, payload)
      setSelectedCheckbox(selectedCheckbox => selectedCheckbox.filter(d => d !== item.shortageReasonId));
    }
  }

  // no. of checkboxes in a row/column
  const chunkArray = (array, size) =>
    array.reduce((acc, _, i) =>
      i % size === 0 ? [...acc, array.slice(i, i + size)] : acc, []);

  // Reason for Shortage checkboxs
  const ShortageReasonsComponent = ( shortageReasons ) => {
    const itemsPerColumn = 4;
    const chunks = chunkArray(shortageReasons, itemsPerColumn);

    return (
      <Row>
        {chunks.map((chunk, chunkIndex) => (
          <Col md={4} key={`chunk-${chunkIndex}`}>
            <Form.Group>
              {chunk.map((item, itemIndex) => (
                <Form.Check
                  key={`item-${item.shortageReasonId}-${itemIndex}`}
                  type="checkbox"
                  id={`custom-switch-${item.shortageReasonId}`}
                  onChange={(e) => handleChangeUpdateShortageReasons(e, item)}
                  checked={selectedCheckbox.some(d => d === item.shortageReasonId) || item.isSelected}
                  label={item.shortageReason}
                />
              ))}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };
  
  // Create a Date object from the string
  function extractDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formattedDate = `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${year}`;
    return formattedDate;
  }

  // handle click make change
  const handleMakeChangeModalClose = (e) => {
    e.preventDefault()
    if (rescheduleData.RescheduleChoicesId && startDate) {
      let payload = {
        "JobNo": parseInt(jobNum),
        "Reason": rescheduleData.RescheduleChoicesId,
        "Note": rescheduleData.changeJobNotes,
        "OrderInstallationDate": startDate ? extractDate(startDate) : null,
        "CreatedBy": parseFloat(auth[0].userId)
      }
      saveCustomerOrderRescheduleData(auth[0].token, payload)
    } else {
      if (!rescheduleData.RescheduleChoicesId) {
        toast.error('Rescheduled reason is required')
      } else if (!startDate) {
        toast.error('Rescheduled date is required')
      }
    }
  }

  // handle installation date
  const handleInstallationDate = (customerOrderData) => {
    let tempDate = customerOrderData?.orderInstallationDetails && customerOrderData?.orderInstallationDetails.InstallationDate ? handleFormatDate(customerOrderData?.orderInstallationDetails.InstallationDate) : ""
    return tempDate
  }

  // handle rescheduled data
  const handleFetchRescheduledData = () => {
    fetchCustomerOrderRescheduledData(auth[0].token, customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId)
  }

  // function to handle Reschedule click
  const handleReschedule = () => {
    handleFetchRescheduledData()
    setChangeJobHeader('Reschedule')
  }

  // handle shortage data
  const handleGetShortageReason = () => {
    getShortageReasonRequest(auth[0].token, '')
  }

  // function to handle Shortage Click
  const handleShortage = () => {
    handleGetShortageReason()
    setChangeJobHeader('Shortage')
  }

  // handle quality
  const handleQuality = () => {
    getQualityReasonRequest(auth[0].token, '')
    setChangeJobHeader('Quality')
  }

  // handle click property name
  const handlePropertyClickForOrdering = (customer) => {
    console.log('-----handlePropertyClickForOrdering', customer)
    setProcessStart(true)
    let url = customer?.crossDomainLink
    localStorage.setItem("place-order-contactInfoId", customer?.contactInfoId)
    let modified_url = url.replace("select-plan", "property-options")
    let authValue = JSON.parse(localStorage.getItem('geoErp'))
    let uniqueChannelName = `${modified_url}&uniqueChannelName=${authValue[0].uniqueChannelName}&editUnitId=${state.newUnitId}&editUnitTypeId=${state.newUnitTypeId}`
    setIsOrderingUrl(uniqueChannelName)
    // handlePropertyModalOpen()
  }

  useMemo(() => {
    if (processStart) {
      let authValue = JSON.parse(localStorage.getItem('geoErp'))
      let channel = pushervalue.subscribe(authValue[0]?.uniqueChannelName);
      localStorage.setItem('Existing_Orders', JSON.parse(false))
      channel.bind("update-order-status", (data) => {
        if(data?.PusherTrigger === "installation-detail-status") {
          navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${data?.UnitId}/jobNum=${data?.JobNum}`)
          handlePropertyModalClose()
        }
        if(data?.PusherTrigger === "close-ordering-popup") {
          window.location.reload();
        }
      });
    }
  }, [processStart]);

  // handle Odering model
  const handleOrdering = () => {
    console.log('--call handleOrdering---')
    handleMakeChangeModalOpen()
    handlePropertyClickForOrdering(customerData[0])
    setChangeJobHeader('Ordering')
  }

  useEffect(() => {
    if (saveOrderShortage && saveOrderShortage.result) {
      if (changeJobHeader === 'Shortage' && saveOrderShortage.result[0] === 'Order shortage saved successfully') {
        toast.success(saveOrderShortage.result[0])
        fetchOrderInstallationDetail(auth[0].token, jobNum, orderedPlaced())
        fetchOrderInstallationMaterial(auth[0].token, jobNum)
        fetchOrderInstallationOrder(auth[0].token, jobNum)
        handleMakeChangeClose()
      } else if (changeJobHeader === 'Shortage' && saveOrderShortage.error) {
        toast.error(saveOrderShortage.error)
      }
    }
  }, [saveOrderShortage])

  const handleAddOn = () => {
    fetchCustomerOrderAddOnDetails(auth[0].token, jobNum)
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":false,"addNewLaborItem":false}))
    setChangeJobHeader('Add On')
  }

  useEffect(() => {
    if(localStorage.getItem("addOnModal")){
      localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":false,"addNewMaterialItemClicked":false,"addNewLaborItem":false}))
    }
    loadLocalStorageDataSetup()
    loadLocalStorageLaborDataSetup()
  },[])

  // handle reschedule cancel button
  const handleRescheduleCancel = () => {
    setChangeJobHeader('Change Job')
    setStartDate()
    setRescheduleData({
      'RescheduleChoicesId': '',
      'changeJobNotes': ''
    })
  }

  // handle add on form data
  const handleAddOnFormChange = (e) => {
    const { id, value } = e.target
    setAddOnData({
      ...addOnData,
      [id]: value
    })
  }

  // handle addon submit details
  const handleAddOnSubmit = (data) => {
    if (addOnData.approvedBy && addOnData.approvedBy.length > 0) {
      let payload = {
        "JobNo": parseInt(jobNum),
        "orderAddOnDetail": {
          "ApprovedBy": addOnData.approvedBy,
          "Description": addOnData.description,
          "EnteredBy": auth[0].userId
        },
        "addOnProducts": data.addOnProducts,
        "addOnMaterials": data.addOnMaterials,
        "addOnLabors": data.addOnLabors
      }
    saveCustomerOrderAddOnDetails(auth[0].token, payload)
    } else {
      toast.error('Please fill all the required fields')
    }
  }

  // handle addon submit details
  const handleorderShortageSubmit = (data) => {
    if (data) {
      let updatedData = {
        ...data,
        orderShortageDetail: {
          ...data.orderShortageDetail,
          enteredBy: auth[0].userId, 
        },
      };
      saveOrderShortageRequest(auth[0].token, updatedData)
    } else {
      toast.error('Please fill all the required fields')
    }
  }

  // handle add on cancel
  const handleAddOnCancel = () => {
    setChangeJobHeader('Change Job')
    setAddOnData({
      'approvedBy': '',
      'description': ''
    })
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
  }

  // function to handle sum of line price
  const handleAddOnSum = (addOnData) => {
    let sum = 0
    if (addOnData && addOnData.length > 0) {
      addOnData.map(data => {
        if (data?.linePrice) {
          sum += data.linePrice
        }
      })
    }
    return sum
  }

  // Function to handle add on total
  const handleAddOnTotal = (data) => {
    let dataLength = Object.keys(data).length;
    if (dataLength && dataLength > 0) {
      let addOnTotal = 0
      if (data?.products && data?.products.length > 0) {
        let productSum = handleAddOnSum(data?.products)
        addOnTotal += productSum
      }
      if (data?.materials && data?.materials.length > 0) {
        let materialSum = handleAddOnSum(data?.materials)
        addOnTotal += materialSum
      }
      if (data?.labors && data?.labors.length > 0) {
        let laborSum = handleAddOnSum(data?.labors)
        addOnTotal += laborSum
      }

      return addOnTotal.toFixed(2)
    }
  }

  // function to calculate the totalPrice 
  const calculateTotal = (material, product, labor) =>{
    let totalAmount = 0;
    if(material.length !== 0){
      for(let obj of material){
        totalAmount += parseFloat(obj?.linePrice)
      }
    }
    if(product.length !== 0){
      for(let obj of product){
        totalAmount +=  parseFloat(obj.linePrice)
      }
    }
    if(labor.length !== 0){
      for(let obj of labor){
        totalAmount +=  parseFloat(obj.linePrice)
      }
    }
    return parseFloat(totalAmount).toFixed(2);
  }

  // function to tract state change
  const handleStateChange = () => {
    setIsStateChanged(!isStateChanged)
  }

  return (
    <MainWrapper>
      {isProcessing || isLoading ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
      <Container>
        {customerOrderData && customerOrderData.map((data, index) => (
          <Card className="mb-5" key={index}>
            <Card.Body>
              { checkOrder &&
                <div className="make-changes text-end mb-4">
                  <div data-bs-toggle="modal" onClick={handleMakeChangeClick} role="button" className="btn btn-primary">
                    Make Changes
                  </div>
                </div>
              }
              <Row className="justify-content-center">
                <Col md={5}>
                  <div className="order-detail">
                    <h3 className="mb-4">Order Details</h3>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="col-md-4">Property Name</Form.Label>
                      <span className="value col-md-8" id="propertyname">{data?.orderInstallationDetails.ShipToPropertyName}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">Address</Form.Label>
                      <span className="value col-md-8" id="address">{data?.orderInstallationDetails.ShipToAddress}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">City</Form.Label>
                      <span className="value col-md-8" id="city">{data?.orderInstallationDetails.ShipToCity}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">State</Form.Label>
                      <span className="value col-md-8" id="state">{data?.orderInstallationDetails.ShipToState}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">Zip</Form.Label>
                      <span className="value col-md-8" id="zip">{data?.orderInstallationDetails.ShipToZip}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="name" className="col-md-4">Requestor Name</Form.Label>
                      <span className="value col-md-8" id="name">{data?.orderInstallationDetails.RequesterName}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="email" className="col-md-4">Requestor Email</Form.Label>
                      <span className="value col-md-8" id="email">{data?.orderInstallationDetails.RequesterEmail}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="phone" className="form-label col-md-4">Requestor Phone Number</Form.Label>
                      <span className="value col-md-8" id="phone">{data?.orderInstallationDetails.PhoneNo}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">Unit#</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control col-md-8" name="newUnitId" id="newUnitId" onChange={handleChange} value={state.newUnitId} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">Unit Type</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="newUnitTypeId" id="newUnitTypeId" onChange={handleChange} value={state.newUnitTypeId} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="inst-date" className="form-label col-md-4">Installation Date</Form.Label>
                      <span className="value col-md-8" id="inst-date">{handleInstallationDate(data)}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="description" className="form-label col-md-4">Description</Form.Label>
                      <div className="col-md-8">
                        <Form.Control as="textarea" className="form-control" name="description" id="description" onChange={handleChange} value={state.description} row={3} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="occupied" className="form-label col-md-4">Occupied</Form.Label>
                      <span className="value col-md-8" id="occupied">{data?.orderInstallationDetails.Occupaied ? 'Yes' : 'No'}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="purchaseOrder" className="form-label col-md-4">Purchase Order</Form.Label>
                      <span className="value col-md-8" id="purchaseOrder">{data?.orderInstallationDetails.PurchaseOrder}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="salesperson" className="form-label col-md-4">Salesperson</Form.Label>
                      <span className="value col-md-8" id="salesperson">{data?.orderInstallationDetails.SalesPerson}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="jobNumber" className="form-label col-md-4">Job Number</Form.Label>
                      <span className="value col-md-8" id="jobNumber">{data?.orderInstallationDetails.JobNo}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="orderTotal" className="form-label col-md-4">Total</Form.Label>
                      <span className="value col-md-8" id="orderTotal">{orderTotal ? handleFormat(parseFloat(orderTotal)) : null}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="tax" className="form-label col-md-4">Tax</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="tax" id="tax" onChange={handleChange} value={state.tax} />
                      </div>
                      <span className="value col-md-8" id="zip"></span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="internalNotes" className="form-label col-md-4">Internal Notes</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="internalNotes" id="internalNotes" onChange={handleChange} value={state.internalNotes} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label for="installerInstructions" className="form-label col-md-4">Installer Instructions</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="installerInstructions" id="installerInstructions" onChange={handleChange} value={state.installerInstructions} />
                      </div>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center my-3">
                <Col md={3}>
                  <div className="plan-img">
                    <img className="img-fluid view-img" src={customerOrderData[index]?.orderInstallationDetails.MeasureImagePng} />
                  </div>
                </Col>
              </Row>
              <hr className="mt-0" />
              <div className="order-detail py-0">
                <h5 className="mb-2">Material</h5>
                <div className="fixTableHead mb-3">
                  <Table bordered className="mb-0 t-scroll">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Private Name</th>
                        <th>Style</th>
                        <th>Color</th>
                        <th>Unit Price</th>
                        <th>Line Price</th>
                        { !checkOrder &&
                          <th>Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {materialListData && materialListData.length > 0 && materialListData.map((data, index) => (
                        <tr key={index}>
                          <td width="110">
                            <Input 
                              ids={`materialQuantityItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'quantity')}
                              value={data.quantity ? data.quantity : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td>{_.get(data, "unitName", "")}</td>
                          <td>{_.get(data, "privateName", "")}</td>
                          <td>{_.get(data, "product", "")}</td>
                          <td>{_.get(data, "color", "")}</td>
                          <td width="110">
                            <Input 
                              ids={`materialUnitpriceItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'unitPrice')}
                              value={data.unitPrice ? data.unitPrice : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td width="110">
                            <Input 
                              ids={`materialLinePriceItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'linePrice')}
                              value={data.linePrice ? data.linePrice : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          { !checkOrder &&
                            <td><Button variant="link" className="text-danger p-0" onClick={e=>handleMaterialDeleteClick(data)}><Icon size={24} icon={ic_delete_outline}/></Button></td>
                          }
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={5} align="right"><div className="form-control border-0 bg-transparent py-0">Total</div></td>
                        <td><div className="form-control border-0 bg-transparent py-0">{totalMaterialUnitPrice ? handleFormat(parseFloat(totalMaterialUnitPrice)) : parseFloat(0.00).toFixed(2)}</div></td>
                        <td><div className="form-control border-0 bg-transparent py-0">{totalMaterialLinePrice ? handleFormat(parseFloat(totalMaterialLinePrice)) : parseFloat(0.00).toFixed(2)}</div></td>
                        { !checkOrder && <td></td> }
                      </tr>
                    </tbody>
                  </Table>
                </div>
                { checkOrder ? '' :
                  <div className="d-block text-center">
                    <div data-bs-toggle="modal" role="button" className="btn btn-primary" onClick={handleClickNewMaterialItem}>
                      Add New Material Item
                    </div>
                  </div>
                }
                <hr />
                <h5 className="mb-2">Labor</h5>
                <div className="fixTableHead mb-3">
                  <Table bordered className="mb-0">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Labor Category</th>
                        <th>Labor Name</th>
                        <th>Unit Price</th>
                        <th>Line Price</th>
                        { !checkOrder &&
                          <th>Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {orderInstallationListData && orderInstallationListData.length > 0 && orderInstallationListData.map((data, index) => (
                        <tr key={index}>
                          <td width="110">
                            <Input 
                              ids={`laborQuantityItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'quantity')}
                              value={data.quantity ? data.quantity : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td>{_.get(data, "unitName", "")}</td>
                          <td>{_.get(data, "laborCategory", "")}</td>
                          <td>{_.get(data, "labor", "")}</td>
                          <td width="110">
                            <Input 
                              ids={`laborUnitpriceItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'unitPrice')}
                              value={data.unitPrice ? data.unitPrice : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td width="110">
                            <Input 
                              ids={`laborLinePriceItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'linePrice')}
                              value={data.linePrice ? data.linePrice : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          { !checkOrder &&
                            <td><Button variant="link" className="text-danger p-0" onClick={e=>handleLaborDeleteClick(data)}><Icon size={24} icon={ic_delete_outline}/></Button></td>
                          }
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} align="right"><div className="form-control border-0 bg-transparent py-0">Total</div></td>
                        <td><div className="form-control border-0 bg-transparent py-0">{totalLaborUnitPrice ? handleFormat(parseFloat(totalLaborUnitPrice)) : parseFloat(0.00).toFixed(2)}</div></td>
                        <td><div className="form-control border-0 bg-transparent py-0">{totalLaborLinePrice ? handleFormat(parseFloat(totalLaborLinePrice)) : parseFloat(0.00).toFixed(2)}</div></td>
                        { !checkOrder && <td></td> }
                      </tr>
                    </tbody>
                  </Table>
                </div>
                { checkOrder ? '' :
                  <div className="d-block text-center mb-4">
                    <div data-bs-toggle="modal" onClick={handleClickNewLabourItem} role="button" className="btn btn-primary">
                      Add New Labor Item
                    </div>
                  </div>
                }
                <hr />

                { checkOrder && customerOrderData && customerOrderData[0].orderAddOns.length > 0 && customerOrderData[0].orderAddOns.map((customerAddOndata, customerAddOnIndex) => (
                  <div className="addon-container" key={customerAddOnIndex}>
                    <div className="text-center">
                      <h5>Add On #{customerAddOnIndex+1}</h5>
                    </div>
                    <ListGroup horizontal className="mb-3">
                      <ListGroup.Item className="border-0 ps-0"><strong>Approved By: {customerAddOndata?.addOnDetail?.approvedBy}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Date: {handleFormatDate(customerAddOndata?.addOnDetail?.approvedDate)}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Entered By: {customerAddOndata?.addOnDetail?.enteredBy}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Description: {customerAddOndata?.addOnDetail?.description}</strong></ListGroup.Item>
                    </ListGroup>
                    <div className="mb-3">
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            <th colSpan="3"></th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          { customerAddOndata?.products && customerAddOndata?.products.length > 0 && customerAddOndata?.products.map((productsAddOnData, productsAddOnIndex) => (
                            <tr key={productsAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.quantity)} disabled /></td>
                              <td>{productsAddOnData?.unitName}</td>
                              <td>{productsAddOnData?.privateName}</td>
                              <td>{productsAddOnData?.style}</td>
                              <td>{productsAddOnData?.color}</td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.unitPrice)} disabled /></td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.linePrice)} disabled /></td>
                            </tr>
                          ))}
                          { customerAddOndata?.materials && customerAddOndata?.materials.length > 0 && customerAddOndata?.materials.map((materialAddOnData, materialAddOnIndex) => (
                            <tr key={materialAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.quantity)} disabled /></td>
                              <td>{materialAddOnData?.unitName}</td>
                              <td>{materialAddOnData?.privateName}</td>
                              <td>{materialAddOnData?.style}</td>
                              <td>{materialAddOnData?.color}</td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.unitPrice)} disabled /></td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.linePrice)} disabled /></td>
                            </tr>
                          ))}
                          { customerAddOndata?.labors && customerAddOndata?.labors.length > 0 && customerAddOndata?.labors.map((laborAddOnData, laborAddOnIndex) => (
                            <tr key={laborAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.quantity)} disabled /></td>
                              <td>{laborAddOnData?.unitName}</td>
                              <td>{laborAddOnData?.laborCategory}</td>
                              <td>{laborAddOnData?.labor}</td>
                              <td></td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.unitPrice)} disabled /></td>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.linePrice)} disabled /></td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={'5'} className="border-0 text-end"></td>
                            <td colSpan={'2'} className="border-0 text-end"><strong><h5>Total:  ${handleAddOnTotal(customerAddOndata)}</h5></strong></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
                </div>
                <div className="addon-container" >
                {(orderShortageList && orderShortageList.length > 0)? 
                  orderShortageList.map((data,idx)=>(
                  <div key={idx}>
                    <div className="text-center">
                      <h5>Shortage #{idx+1}</h5>
                    </div>
                    <ListGroup horizontal className="mb-3">
                      <ListGroup.Item className="border-0 ps-0"><strong>Called in By: {data?.orderShortageDetail?.calledInBy ? data?.orderShortageDetail?.calledInBy : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Date: {data?.orderShortageDetail?.approvedDate ? formatDate(data?.orderShortageDetail?.approvedDate) : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Entered By: {data?.orderShortageDetail?.enteredBy ? data?.orderShortageDetail?.enteredBy : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Description: {data?.orderShortageDetail?.description ? data?.orderShortageDetail?.description : ""}</strong></ListGroup.Item>
                    </ListGroup>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label className="form-label">
                            Reason for Shortage
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Row>
                          {data?.orderShortageReasons ? ShortageReasonsComponent(data?.orderShortageReasons, data?.orderShortageReasons) : ""}
                        </Row>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            {/* <th width="100">Style</th> */}
                            <th colSpan="3"></th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(data?.orderShortageProduct && data?.orderShortageProduct.length > 0 ) ? data?.orderShortageProduct.map((kdata) => (
                          <tr key={kdata.orderShortageProductId}>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0)) ? parseFloat(kdata.quantity).toFixed(2) : ""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td>{kdata.style ? kdata.style : ""}</td>
                            <td>{kdata.color ? kdata.color : ""}</td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0))? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/></td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/></td>
                          </tr>)) : ""}
                          {(data?.orderShortageMaterial && data?.orderShortageMaterial.length > 0 ) ? data?.orderShortageMaterial.map((kdata) => (
                          <tr key={kdata.orderShortageMaterialId}> 
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0)) ? parseFloat(kdata.quantity).toFixed(2):""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td>{kdata.style ? kdata.style : ""}</td>
                            <td>{kdata.color ? kdata.color : ""}</td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0)) ? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/></td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/></td>
                          </tr>)) : ""}
                          {(data?.orderShortageLabor && data?.orderShortageLabor.length > 0 ) ? data?.orderShortageLabor.map((kdata) => (
                          <tr key={kdata.orderShortageLaborId}>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0))? parseFloat(kdata.quantity).toFixed(2):""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td >{kdata.laborCategory ? kdata.laborCategory : ""}</td>
                            <td>{kdata.labor ? kdata.labor : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0)) ? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/></td>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/></td>
                          </tr>)) : ""}
                          <tr>
                            <td colSpan={'5'} className="border-0 text-end"></td>
                            <td colSpan={'2'} className="border-0 text-end"><strong><h5>Total:  {calculateTotal(data?.orderShortageMaterial, data?.orderShortageProduct, data?.orderShortageLabor)}</h5></strong></td>
                          </tr>
                        </tbody>
                      </Table>
                     </div>
                  </div>
                  ))
                : "" }
                { !checkOrder &&
                  <div className="d-block text-center mb-4">
                    <div data-bs-toggle="modal" role="button" className="btn btn-primary me-2" onClick={handleSaveClick}>Submit</div>
                    <div data-bs-toggle="modal" role="button" className="btn btn-primary" onClick={handleCancelClick}>Cancel</div>
                  </div>
                }
              </div>
            </Card.Body>
          </Card>
        ))}
  		</Container>
      {materialContractModalOpen && !laborContractModalOpen &&
        <PricingContract
          className="mb-2"
          storeId={customerData[0].storeId}
          contactInfoId={[contactInfoId]}
          pricingContractModalOpen={pricingContractModalOpen}
          showPricingContractModal={showPricingContractModal}
          hidePricingContractModal={hidePricingContractModal}
          isContractPricingAdded={customerData[0]?.isContractPricingAdded}
          changeReducerState={changeReducerState}
          handleUpdateCustomerDetail={handleUpdateCustomerDetail}
          materialContractModalOpen={materialContractModalOpen}
          orderInstallationDetailId={customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId}
          saveContractForOrderDetails={saveContractForOrderDetails}
          unitId={unitId}
          jobNum={jobNum}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
        />
      }
      {laborContractModalOpen && !materialContractModalOpen ? (
        <PricingContract
          className="mb-2"
          storeId={customerData[0].storeId}
          contactInfoId={[contactInfoId]}
          pricingContractModalOpen={pricingContractModalOpen}
          showPricingContractModal={showPricingContractModal}
          hidePricingContractModal={hidePricingContractModal}
          isContractPricingAdded={customerData[0].isContractPricingAdded}
          changeReducerState={changeReducerState}
          handleUpdateCustomerDetail={handleUpdateCustomerDetail}
          laborContractModalOpen={laborContractModalOpen}
          saveContractForOrderDetails={saveContractForOrderDetails}
          orderInstallationDetailId={customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId}
          unitId={unitId}
          jobNum={jobNum}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
        />
      ) : ""}
      {confirmDeleteModal && <ConfirmDelete 
        variant="primary"
        title={`order-confirmation ${deleteOrder} data`}
        showConfirmDeleteModal={showConfirmDeleteModal}
        hideConfirmDeleteModal={hideConfirmDeleteModal}
        handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
        confirmDeleteModal={confirmDeleteModal}
      />}
      {isChangeJob && (
        <ChangeJob
          className="mb-2"
          handleMakeChangeModalOpen={handleMakeChangeModalOpen}
          handleMakeChangeModalClose={handleMakeChangeModalClose}
          startDate={startDate}
          setStartDate={setStartDate}
          handleRescheduleChange={handleRescheduleChange}
          handleMakeChangeClose={handleMakeChangeClose}
          handleFetchRescheduledData={handleFetchRescheduledData}
          rescheduleData={rescheduleData}
          changeJobHeader={changeJobHeader}
          setChangeJobHeader={setChangeJobHeader}
          handleRescheduleCancel={handleRescheduleCancel}
          handleReschedule={handleReschedule}
          handleShortage={handleShortage}
          handleQuality={handleQuality}
          handleOrdering={handleOrdering}
          handleAddOn={handleAddOn}
          handleClickNewMaterialItem={handleClickNewMaterialItem}
          handleClickNewLabourItem={handleClickNewLabourItem}
          handleAddOnFormChange={handleAddOnFormChange}
          addOnData={addOnData}
          materialListData={materialListData}
          orderInstallationListData={orderInstallationListData}
          handleAddOnSubmit={handleAddOnSubmit}
          handleorderShortageSubmit={handleorderShortageSubmit}
          handleAddOnCancel={handleAddOnCancel}
          orderAddOnDetails={orderAddOnDetails}
          handleFormat={handleFormat}
          handleStateChange={handleStateChange}
          shortageReason={shortageReason}
          jobNum={jobNum}
          qualityReasons={qualityReasons}
          auth={auth}
          customerData={customerData}
          orderingUrl={orderingUrl}
          saveOrderQualityDetailRequest={saveOrderQualityDetailRequest}
          saveOrderQualityDetail={saveOrderQualityDetail}
          localStorageData={localStorageData}
          localStorageLaborData={localStorageLaborData}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
          lsAllLaborMaterialCarpetData={lsAllLaborMaterialCarpetData}
        />
      )}
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, customerInfo, pricingContract }) => {
	return {
		auth, customerInfo, pricingContract
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrderInstallationDetail: (token, jobNum, checkOrder) => dispatch({ type: CUSTOMER_UNIT_DETAIL_REQUEST, token, jobNum, checkOrder }),
		fetchCustomerList: (token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked) => dispatch({ type: CUSTOMER_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }),
		saveCustomerOrderDetails: (token, data) => dispatch({ type: CUSTOMER_ORDER_DETAIL_REQUEST, token, data }),
    changeReducerState: () => dispatch({ type: CUSTOMER_STATE_CHANGE }),
		fetchOrderInstallationMaterial: (token, jobNum) => dispatch({ type: CUSTOMER_ORDER_MATERIAL_REQUEST, token, jobNum }),
		fetchOrderInstallationOrder: (token, jobNum) => dispatch({ type: CUSTOMER_ORDER_INSTALLATION_REQUEST, token, jobNum }),
    saveCustomerOrderDetailsForPricingContract: (token, data) => dispatch({ type: SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST, token, data }),
    deleteOrderMaterialData: (token, id, checkFlag) => dispatch({ type: CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST, token, id, checkFlag }),
    deleteOrderLaborData: (token, id) => dispatch({ type: CUSTOMER_ORDER_LABOR_DELETE_REQUEST, token, id }),
    saveCustomerOrderUnitId: (token, data) => dispatch({ type: CUSTOMER_ORDER_UNIT_CHANGE_REQUEST, token, data }),
    saveCustomerOrderUnitTypeId: (token, data) => dispatch({ type: CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST, token, data }),
    saveCustomerOrderRescheduleData: (token, data) => dispatch({ type: CUSTOMER_ORDER_RESCHEDULE_REQUEST, token, data }),
    fetchCustomerOrderRescheduledData: (token, id) => dispatch({ type: CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST, token, id }),
    fetchCustomerOrderAddOnDetails: (token, id) => dispatch({ type: CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST, token, id }),
    saveCustomerOrderAddOnDetails: (token, data) => dispatch({ type: CUSTOMER_ORDER_ADDON_SAVE_REQUEST, token, data }),
    getShortageReasonRequest: (token, data) => dispatch({ type: GET_SHORTAGE_REASON_REQUEST, token, data }),
    saveOrderShortageRequest: (token, data) => dispatch({ type: SAVE_ORDER_SHORTAGE_DETAIL_REQUEST, token, data }),
    updateShortageReasonRequest: (token, data) => dispatch({ type: UPDATE_SHORTAGE_REASON_REQUEST, token, data }),
    getQualityReasonRequest: (token, data) => dispatch({ type: GET_QUALITY_REASON_REQUEST, token, data }),
    saveOrderQualityDetailRequest: (token, data) => dispatch({ type: SAVE_ORDER_QUALITY_DETAIL_REQUEST, token, data }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);