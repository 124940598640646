import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { toast } from 'react-toastify';

const Quality = ({
  qualityReasons, 
  auth, jobNum, 
  saveOrderQualityDetailRequest, 
  saveOrderQualityDetail, 
  handleMakeChangeClose, 
  handleOrdering
}) => {

  const [qltyReasons,setQltyReasons] = useState(null)
  const [orderQualityDetail, setOrderQualityDetail] = useState({"calledInBy": "","description":"","enteredBy": auth[0].userId})

  // update the quality reasons checkboxes
  const handleChangeQualityReasons = (e, qualityReasonId) => {
    const isChecked = e.target.checked;
    setQltyReasons((prevReasons) =>
      prevReasons.map((reason) =>
        reason.qualityReasonId === qualityReasonId
          ? { ...reason, isSelected: isChecked }
          : reason
      )
    );
  };

  // quality form validation
  const validateQualityForm = ( payload ) => {
    const { orderQualityDetail, orderQualityReasons } = payload;
    const isAnyReasonSelected = orderQualityReasons.some(reason => reason.isSelected);
    if (!isAnyReasonSelected) {
      toast.error("At least one quality reason must be selected.");
      return false; 
    }
    if (!orderQualityDetail.calledInBy || orderQualityDetail.calledInBy.trim() === "") {
      toast.error("Called In By field should not be empty.");
      return false; 
    }
    return true;
  }

  // for close the quality modal
  const handleQualityCloseModal = () => {
    setOrderQualityDetail({"calledInBy": "","description":"","enteredBy": auth[0].userId})
    handleMakeChangeClose()
  }

  // on change update the data in state
  const handleChangeOrderQualityDetail = (e) => {
    const {name, value} = e.target
    setOrderQualityDetail((prev)=>({
      ...prev,
      [name]: value
    }))
  }

  // submit the quality modal form
  const handleQualitySubmit = () => {
    let payload = {"jobNo": parseInt(jobNum),"orderQualityDetail" : orderQualityDetail, "orderQualityReasons" : qltyReasons}
    const isValid = validateQualityForm(payload);
    console.log('payload',  isValid, payload)
    if (isValid) {
      saveOrderQualityDetailRequest(auth[0].token, payload)
      handleQualityCloseModal()
      handleOrdering()
    }
  }

  const chunkArray = (array, size) => {
    if (array){
      return array.reduce((acc, _, i) =>
      i % size === 0 ? [...acc, array.slice(i, i + size)] : acc, []);}
  }

  // add the checkboxes
  const QualityReasonsComponent = ( qwltyReasons ) => {
    const itemsPerColumn = 4;
    const chunks = chunkArray(qwltyReasons, itemsPerColumn);

    return (
      <Row>
        {chunks.map((chunk, chunkIndex) => (
          <Col md={4} key={`chunk-${chunkIndex}`}>
            <Form.Group>
              {chunk.map((item, itemIndex) => (
                <Form.Check
                  key={`item-${item.qualityReasonId}-${itemIndex}`}
                  type="checkbox"
                  id={`custom-switch-${item.qualityReasonId}`}
                  onChange={(e) => handleChangeQualityReasons(e, item.qualityReasonId)}
                  checked={qltyReasons.find(reason => reason.qualityReasonId === item.qualityReasonId)?.isSelected || false}
                  label={item.qualityReason}
                />
              ))}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  useEffect(() => {
    if (qualityReasons?.result && qualityReasons?.result.length > 0) {
      const updatedReasons = qualityReasons.result.map((reason) => ({
        ...reason,
        isSelected: false,
      }));
      setQltyReasons(updatedReasons);
    }
  }, [qualityReasons]);

    return (<><Container>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label className="form-label">
                Reason for Quality
                <span className="text-danger">*</span>
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Row>
              {QualityReasonsComponent(qltyReasons ? qltyReasons : []) }
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label className="form-label">
                Called In By
                <span className="text-danger">*</span>
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Control type="text" name="calledInBy" onChange={(e)=>handleChangeOrderQualityDetail(e)} value={orderQualityDetail.calledInBy}/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label className="form-label">
                Description (Include Areas)
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Control as="textarea" name="description" rows={3} onChange={(e) => handleChangeOrderQualityDetail(e)}  value={orderQualityDetail.description}/>
          </Col>
        </Row>
        <hr />
        <div className="text-center mt-3">
          <Button className="mx-auto me-2" onClick={handleQualitySubmit}>Submit</Button>
          <Button className="mx-auto" onClick={handleQualityCloseModal}>Cancel</Button></div> 
      </Container>
    </>
  );
}

export default Quality;
